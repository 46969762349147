import React, { Component } from 'react';
import {
  Button, Col, Dropdown, Row,
} from 'react-bootstrap';
import ImgArr from '../../../assets/images/arr-002.svg';
import imgExport from '../../../assets/images/export.svg';
import imgEyeBlue from '../../../assets/images/eye-blue.svg';
import imgEye from '../../../assets/images/eye.svg';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../utils/premiumIpDisabledEdit';
import { Export } from '../Stories/Export';
import { BookAuthors } from './bookAuthors';
import './books.scss';
import { BookTitle } from './BookTitle';

export class BookItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardStep: null,
    };
    const { auth } = this.props;

    this.user = auth.getUser();
  }

  componentDidUpdate() {
    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    const { wizardStep: wizardStep1 } = this.state;

    if (wizardStep !== wizardStep1) {
      this.setState({
        wizardStep,
      });
    }
  }

  render() {
    const { wizardStep } = this.state;

    const wizardDisabled = wizardStep > 0;
    const {
      share,
      index,
      obj,
      itemIndex,
      disabledRow,
      deleteBook,
      editTitle,
      currentItem,
      editBook,
      cloneBook,
    } = this.props;

    const editDisabled = editTitle || (this.user.role !== 'admin' && obj.book_role !== 'owner') || wizardDisabled;
    const { role } = this.user;

    return (
      <Row
        className="books-list-item d-flex flex-nowrap justify-content-between"
        disabled={disabledRow || wizardDisabled}
        key={index}
      >
        <Col
          sm={4}
          xl={5}
          className="pr-0"
        >
          <i
            className="indicator"
            style={{ backgroundColor: currentItem.props.obj.color }}
          />
          <span className="colNum">{itemIndex + 1}</span>

          <span
            className={`${wizardStep && wizardStep === 4 ? 'wizardActive' : ''}`}
          >
            <BookTitle
              {...this.props}
              disabled={
                (
                  editTitle
                  || (this.user.role !== 'admin' && obj.book_role !== 'owner')
                  || PremiumIpDisabledEdit(this.user.role, obj)
                  || PremiumIpDisabledApprovedEdit(this.user.role, obj)
                  || wizardDisabled
                )
              }
            />

            {
              !!(wizardStep && wizardStep === 4)
              && (
              <div className="sidebarArr">
                <img src={ImgArr} alt="" />
                <span>click to continue</span>
              </div>
              )
            }
          </span>

        </Col>
        <Col sm={4} xl={4} className="text-center px-0 books-list-additionalBox long">
          <BookAuthors obj={obj} user={this.user} />
        </Col>

        <Col sm={4} xl={3} className="text-right px-0 books-list-actionBox">

          <span className="tooltip-box">
            <Button
              disabled={
                  editTitle
                  || (this.user.role !== 'admin' && !['owner', 'editor', 'co-author'].includes(obj.book_role))
                  || PremiumIpDisabledEdit(this.user.role, obj)
                  || PremiumIpDisabledApprovedEdit(this.user.role, obj)
                  || wizardDisabled
                }
              onClick={() => {
                share(obj);
              }}
              variant="secondary"
              size="sm"
              className="ml-1"
            >
              <img
                src={obj.users && obj.users.length > 1 ? imgEyeBlue : imgEye}
                className="btnImg"
                alt="Share"
              />
            </Button>
            <div className="tooltip">
              <div className="arrow" />
              <div className="tooltip-inner">Share</div>
            </div>
          </span>

          {this.user.role !== 'admin' ? null
            : (
              <span className="tooltip-box">
                <Button
                  disabled={editTitle || (this.user.role !== 'admin' && obj.book_role !== 'owner') || wizardDisabled}
                  onClick={() => Export.exportBook(obj.id, obj.title)}
                  variant="secondary"
                  size="sm"
                  className="ml-1"
                >
                  <img src={imgExport} className="btnImg" alt="Export" />
                </Button>
                <div className="tooltip">
                  <div className="arrow" />
                  <div className="tooltip-inner">Export</div>
                </div>
              </span>
            )}
          <Dropdown
            className="mx-1 d-inline-block"
          >
            <Dropdown.Toggle
              variant="secondary"
              id="ellipsis"
              className="mx-1"
              size="sm"
            >
              ...
            </Dropdown.Toggle>
            <Dropdown.Menu>

              <Dropdown.Item
                as="button"
                disabled={editDisabled}
                onMouseDown={() => {
                  editBook(obj);
                }}
              >
                Edit
              </Dropdown.Item>
              {(role === 'admin')
              && (
              <>
                {
                  (obj && !obj.isPrompt)
                  && (
                  <Dropdown.Item
                    as="button"
                    onMouseDown={() => {
                      cloneBook(obj);
                    }}
                  >
                    Clone
                  </Dropdown.Item>
                  )
                }
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  disabled={editTitle || wizardDisabled}
                  onMouseDown={() => {
                    deleteBook(obj);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    );
  }
}
